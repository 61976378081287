import * as api from './http'

/* 获取短信验证码 */
export function sms(params){
    return api.postApi('Login/sms', params)
}

/* 手机号注册并登陆 */
export function phone_register(params){
    return api.postApi('Login/phone_register', params)
}

/* 密码/验证码登录 */
export function password_login(params){
    return api.postApi('Login/password_login', params)
}

/* 忘记密码 */
export function forget_password(params){
    return api.postApi('Login/forget_password', params)
}