<template>
    <!-- 注册账号 && 忘记密码 -->
    <div class="register">
        <div class="registerContent centerWidth marginAuto flex flexColumn alignCenter">
            <p class="c33 fontW" v-show="showType == 1">{{registerType == 1 ? '个人账号注册' : '机构账号注册'}}</p>
            <p class="c33 fontW" v-show="showType == 2">忘记密码</p>
            <h6 class="c33" v-show="showType == 1">已有账号？<span class="cSkyBlue" @click="goother({path:'/login'})">请登录</span></h6>
            <table>
                <tr>
                    <td style="text-align:left;width:85px;" class="c33 ">手机号码</td>
                    <td style="width: 310px;">
                        <el-input v-model="userInfo.phone" placeholder="请填写手机号码"></el-input>
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;width:85px;" class="c33 ">验证码</td>
                    <td style="width: 330px;height:80px;" class="flex spacebetween alignCenter">
                        <el-input v-model="userInfo.code" placeholder="请填写验证码" style="width:168px;"></el-input>
                        <button @click="sendCode">{{getCode}}</button>
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;width:85px;" class="c33 ">登录密码</td>
                    <td style="width: 310px;">
                        <el-input v-model="userInfo.password" placeholder="长度6-18位，可字母+数字，区分大小写" show-password></el-input>
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;width:85px;" class="c33 ">确认密码</td>
                    <td style="width: 310px;">
                        <el-input v-model="userInfo.confirm_password" placeholder="请和登录密码保持一致" show-password></el-input>
                    </td>
                </tr>
            </table>
            <button class="submit marginAuto" @click="nowRegister">{{btntext}}</button>
        </div>
    </div>
</template>
<script>
import { sms, phone_register, forget_password } from '@/utils/Api/doLogin'
import axios from 'axios';
export default {
    name: 'register',
    data(){
        return{
            registerType: 1,                            // 注册类型 1 个人 2 机构
            getCode: '获取验证码',
            times: 60,
            codeTimer: null,
            userInfo:{},
            btntext:'',
            showType: 2,                                // 注册账号 忘记密码 共用此页面  值为 1 注册账号 2 忘记密码
			inviteType: null,							// 后台传入的注册类型（邀请注册）
        }
    },
    created(){
        this.btntext = this.$route.query.type == 2 ? '提交' : '立即注册'
        this.showType = this.$route.query.type == 2 ? 2 : 1
        this.registerType = this.$route.query.registerType == 2 ? 2 : 1
		this.inviteType = this.$route.query.invite_type ? this.$route.query.invite_type : ''
        this.getAddress()
    },
    methods:{
        async nowRegister(){
            if(!this.userInfo.phone){
                this.$message.warning('请填写手机号码')
                return
            }
            if(!(/^(1[0-9])\d{9}$/.test(this.userInfo.phone))){
                this.$message.warning('手机号填写不正确')
                return
            }
            if(!this.userInfo.code){
                this.$message.warning('请填写验证码')
                return
            }
            if(!this.userInfo.password){
                this.$message.warning('请填写登录密码')
                return
            }
            if(this.userInfo.password.length < 6){
                this.$message.warning('登录密码填写不正确，长度6-18位，可字母+数字，区分大小写')
                return
            }
            if(!this.userInfo.confirm_password){
                this.$message.warning('请填写确认密码')
                return
            }
            if(!(this.userInfo.password == this.userInfo.confirm_password)){
                this.$message.warning('两次密码输入不一致')
                return
            }
            try{
                let cBack,text
                if(this.showType == 1){
                    cBack = await phone_register({
                        user_type : this.registerType,
                        ...this.userInfo,
						            invite_type: this.inviteType
                    })
                    text = '注册成功！'
                }
                if(this.showType == 2){
                    cBack = await forget_password({
                        ...this.userInfo
                    })
                    text = '密码修改成功！'
                }
                if(cBack) {
                  this.$store.dispatch('user/set_token', cBack.token)
                  this.$message.success(text)
                  setTimeout(() => {
                    this.goother({path: '/', query: {comInforType: this.registerType}})
                  })
                }
            }catch(e) {this.$message.error('哎呀，注册失败啦！');console.log(e)}
        },
        getAddress(){
            axios.post('http://restapi.amap.com/v3/ip?key=81046247d797c3e3d6c4c9df4e054b25').then(res => {
                if(res.status){
                    this.userInfo.province = res.province
                    console.log(this.userInfo)
                    return
                }
                this.$message.error('位置获取失败')
            })
        },
        async sendCode(){
            if(!this.userInfo.phone){
                this.$message.warning('请填写手机号码')
                return
            }
            if(!(/^(1[0-9])\d{9}$/.test(this.userInfo.phone))){
                this.$message.warning('手机号填写不正确')
                return
            }
            if(this.codeTimer){
                this.$message.warning(`请在${this.times}后重新发送！`)
                return
            }
            try{
                await sms({phone: this.userInfo.phone})
                this.$message.success('验证码发送成功！')
            }catch(e){console.log(e)}

            this.codeTimer = setInterval(() => {
                this.times--
                this.getCode = `${this.times}后重新发送`
                if(this.times < 1){
                    clearInterval(this.codeTimer)
                    this.codeTimer = null
                    this.getCode = '获取验证码'
                    this.times = 60
                }
            },1000)
        }
    }
}
</script>
<style lang="scss" scoped>
    .submit{
        width: 391px;
        height: 53px;
        opacity: 1;
        background: #47d7e3;
        border-radius: 1px;
        margin-top: 30px;
        font-size: 16px;
        color: #fff;
    }
    table{
        margin-top: 36px;
        tr{height: 80px;}
        td{
            font-size: 15px;
        }
        button{
            width: 133px;
            height: 40px;
            opacity: 1;
            background: #47d7e3;
            border-radius: 1px;
            color: #fff;
            font-size: 16px;
            margin-left: 6px;
        }
        input{
            color: #333;
            width: 307px;
            height: 53px;
            opacity: 1;
            border: 1px solid #c6c6c6;
            border-radius: 2px;
            padding-left: 17px;
            font-size: 15px;
        }
    }
    .register{
        width: 100%;
        height: 668px;
        background: #fafafa;
        overflow: hidden;
        .registerContent{
            margin-top: 28px;
            // margin-bottom: 46px;
            height: 594px;
            background: #fff;
            overflow: hidden;
            p{
                margin-top: 44px;
                font-size: 22px;
            }
            h6{
                font-size: 16px;
                margin-top: 26px;
                span{
                    cursor: pointer;
                }
            }
        }
    }
</style>